import {Injectable, signal} from '@angular/core'
import {Router} from '@angular/router'
import {
  HelperService,
  SingleSignOnService,
  SparbankenUser,
  TokenPayload
} from '@sparbanken-syd/sparbanken-syd-bankid'
import {
  BehaviorSubject,
  catchError,
  EMPTY,
  Observable,
  of,
  switchMap
} from 'rxjs'
import {environment} from '../../environments/environment'

/**
 * We export this so that we can use it in test
 */
export const ADMIN_ROLE_NAME = 'admin'
export const USER_DOC_ROLE_NAME = 'userDocUser'
export const REGISTRAR_ROLE_NAME = 'loanPromiseRegistrar'
export const EMPLOYEE = 'employee'

/**
 * Other parts of the application wants to know this data.
 * Note that we DO NOT send the access token here as it
 * is only needed by the auth interceptor, and it reads it
 * from the Observable.
 */
export interface ILogInState {
  /**
   * Simple yes no for admin if we need it.
   */
  admin: boolean
  userDocAdmin: boolean
  registrar: boolean
  employee: boolean

  /**
   * Below is what we use in this application
   */
  name: string
  sub: string
  phone: string
  office: string
}

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  /**
   * The access token, primarily needed for the auth interceptor
   */
  public accessToken$ = new BehaviorSubject<string | null>(null)

  /**
   * This is for the "admin", you can add additional
   */
  public isAdmin$ = signal<boolean>(false)
  public isRegistrar$ = signal<boolean>(false)
  public isEmployee$ = signal<boolean>(false)
  public isUserDoc$ = signal<boolean>(false)
  public isMockUcActive$ = signal<boolean>(false)

  /**
   * Listen to this when you want to know if the login state has changed.
   */
  public logInState$: Observable<ILogInState | null>

  /**
   * Private so that we prevent others from publishing
   */
  private pLogInState$ = new BehaviorSubject<ILogInState | null>(null)

  constructor(
    private ssoService: SingleSignOnService,
    private helperService: HelperService,
    private router: Router
  ) {
    this.logInState$ = this.pLogInState$.asObservable()
  }

  /**
   * This is called from the app module bootstrapper only. So
   * it will happen once and once only.
   */
  public bootstrap(): Observable<boolean> {
    return this.sso()
      .pipe(
        switchMap((value: string | null) => {
          return this.setToken(value)
        })
      )
  }

  /**
   * Called whenever we have token, a token can come from two valid sources
   *
   * 1. From the SSO service
   * 2. From BankID login.
   *
   * We do not care, and we validate and set whatever we get.
   */
  public setToken(token: string | null): Observable<boolean> {
    const payLoad: TokenPayload | null = HelperService.GetTokenPayload(token)
    if (payLoad) {
      this.accessToken$.next(token)
      this.setUserData(payLoad.roles)
    }
    return of(true)
  }


  /**
   * Call the SSO service, if we get something we return
   * that. Otherwise, nothing. Must be anonymous since we
   * call it from merge
   */
  public sso = (): Observable<string> | never => {
    return this.ssoService.getToken(environment.commonServiceUrl, environment.domain)
      .pipe(
        catchError(() => {
          // We need to reset when the SSO service logs us out, but there's no need to call logout again
          this.reset()
          return EMPTY
        })
      )
  }

  /**
   * Reset all admin values, including SSO and
   * go back to log-in.
   */
  public logout(): void {
    // Blindly just log out from SSO, ignore any errors
    this.ssoService.deleteToken(environment.commonServiceUrl).subscribe()
    this.reset()
  }

  public reset(): void {
    // This can potentially be a long list of resets...
    this.accessToken$.next(null)
    this.isAdmin$.set(false)
    this.isUserDoc$.set(false)
    this.isRegistrar$.set(false)
    this.isEmployee$.set(false)
    this.pLogInState$.next(null)
    // Go to log-in
    this.router.navigate(['/']).then()
  }

  /**
   * In many cases we also want to fetch the user info we do this
   * totally asynchronous and happily accept that the access token
   * is set properly etc.
   *
   * In Legacy applications we have used this to trigger a reset, which is
   * bad.
   */
  private setUserData(roles: string[]): void {
    this.helperService.getCurrentUser(`${environment.commonServiceUrl}`)
      .subscribe({
        next: (user: SparbankenUser) => {
          this.isAdmin$.set(roles.includes(ADMIN_ROLE_NAME))
          this.isUserDoc$.set(roles.includes(USER_DOC_ROLE_NAME))
          this.isRegistrar$.set(roles.includes(REGISTRAR_ROLE_NAME))
          this.isEmployee$.set(roles.includes(EMPLOYEE))

          // Emit login data to subscribers
          this.pLogInState$.next({
            admin: this.isAdmin$(),
            userDocAdmin: this.isUserDoc$(),
            registrar: this.isRegistrar$(),
            employee: this.isEmployee$(),
            name: user.name,
            sub: user.sub,
            office: user.office,
            phone: user.phone as string
          })
        }
      })
  }
}
